import React from 'react';
import { Alert, Spinner } from '@amzn/awsui-components-react';
import { gql, useQuery } from '@apollo/client';
import { messages as untypedMessages } from '../../../../intl';
import { marketplaceToCurrencyCode } from '../../../../util/Localization';
import * as Logger from '../../../../util/Logger';
import { typographyStyles } from '../../../../util/Styles';
import { globalState } from '../../../../util/Session/globalState';
import { titleStyle } from '../../styles';
const messages = untypedMessages;
;
;
export const getCustomerOrderPolicyQuery = (selectedConcessionType) => gql `
    query CustomerOrderPolicy${selectedConcessionType.charAt(0).toUpperCase()}${selectedConcessionType.substring(1)}($customerId: String!, $marketplace: Marketplace!, $orderId: String!) {
        customer(input: {customerId: $customerId, marketplace: $marketplace}) {
            order(input: {orderId: $orderId}) {
                policies {
                    ${selectedConcessionType} {
                        id
                        url
                    }
                }
            }
        }
    }
`;
const marketplaceToCurrencyThreshold = {
    DE: 100,
    ES_PROD: 100,
    ES_DEVO: 100,
    FR: 100,
    IN_DEVO: 0,
    IN_PROD: 0,
    IT_PROD: 100,
    IT_DEVO: 100,
    UK: 100,
    US: 100,
    CA: 100,
    MX_PROD: 2000,
    MX_DEVO: 2000,
};
const getPolicyMessageWithCurrencyThreshold = (policyId, marketplace, intl) => intl.formatMessage(messages[policyId], {
    currency_threshold: intl.formatNumber(marketplaceToCurrencyThreshold[marketplace], {
        style: 'currency',
        currency: marketplaceToCurrencyCode[marketplace],
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    }),
});
const getPolicyMessage = (policyId, intl) => {
    if (!messages[policyId]) {
        return policyId;
    }
    if (policyId === 'POLICE_REPORT_OR_INCIDENT_REPORT' || policyId === 'POLICE_REPORT_OR_THREE_DAY_INVESTIGATION_PERIOD') {
        return getPolicyMessageWithCurrencyThreshold(policyId, globalState.getSignatureValidationFields().marketplace, intl);
    }
    return intl.formatMessage(messages[policyId]);
};
export const FetchAndShowPolicy = ({ intl, inputs, next, logEvent, }) => {
    const { data, loading, error, refetch } = useQuery(getCustomerOrderPolicyQuery(inputs.selectedConcessionType), {
        variables: globalState.getSignatureValidationFields(),
        fetchPolicy: 'no-cache',
        onCompleted: (data) => {
            if (data) {
                Logger.info('CAPWorkflow:FetchAndShowPolicy:succeeded', { inputs, data });
                const policy = data.customer.order.policies[inputs.selectedConcessionType];
                logEvent('PolicyFetched', policy.id);
                next({ ...inputs, policyId: policy.id });
            }
            ;
        },
        onError: (error) => {
            Logger.error('CAPWorkflow:FetchAndShowPolicy:failed', { inputs, error: error });
        },
    });
    if (loading)
        return React.createElement(Spinner, null);
    if (error)
        return (React.createElement(Alert, { buttonText: intl.formatMessage(messages.RETRY), content: intl.formatMessage(messages.FETCH_ERROR_SOMETHING_WENT_WRONG), onButtonClick: () => refetch(), type: 'error' }));
    if (data) {
        const policy = data.customer.order.policies[inputs.selectedConcessionType];
        return React.createElement("div", null,
            React.createElement("h5", { className: titleStyle }, intl.formatMessage(messages.RECOMMENDED_POLICY_STAGE)),
            React.createElement("a", { className: typographyStyles.fontSize1, href: policy.url, onClick: () => logEvent('PolicyClicked', policy.id), rel: 'noopener noreferrer' // See https://mathiasbynens.github.io/rel-noopener
                , target: '_blank' }, getPolicyMessage(policy.id, intl)));
    }
    Logger.error('CAPWorkflow:FetchAndShowPolicy:useQuery:IllegalState', { inputs, loading, data, error });
    throw Error('useQuery returned an unexpected set of outputs');
};
